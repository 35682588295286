<template>
  <div class="components-layout-left" v-if="isTemplate">
    <div style="text-align: center; margin-top: 11px">
      <el-input class="search-box" clearable
                v-model="keyword"
                suffix-icon="el-icon-search"
                placeholder="搜索模板"
                @input="getList"
      />
    </div>
    <div>
      <ul class="importUl">
        <li v-for="(item, index) in templatesList" :key="index">
          <p style="padding-left: 20px; margin-top: 1rem; font-size: 1.5rem;">{{ item.diagramName }}</p>
          <div style="padding: 20px">
            <!-- <img
              src="../assets/images/profile.png"
              alt=""
              style="width: 100%"
              @click="throughTemplate(item)"
            /> -->
            <img
              :src="envApi + item.coverUrl"
              @click="throughTemplate(item)"
              style="width: 100%; height: 100%;border: 1px solid"
              alt=""
            />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import config from "@/config/index.js";
import { listTemplates } from "@/api/diagram/templates";

export default {
  data() {
    return {
      //网站前缀
      envApi: config.baseURL + 'template',
      isTemplate: false,
      templatesList: [],
      keyword: ''
    };
  },
  methods: {
    getList() {
      listTemplates(this.keyword).then((response) => {
        this.templatesList = response.data;
        this.loading = false;
      });
    },
    throughTemplate(item) {
      this.$emit("acceptFormChildrenTemplate", item);
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
<style lang="less" scoped>
::v-deep .el-collapse-item__arrow.is-active {
  transform: rotate(90deg);
}

::v-deep .el-collapse-item__arrow,
.el-tabs__nav {
  transform: rotate(-90deg);
}

.components-layout-left .ant-input-search-icon {
  font-size: 20px !important;
  color: #1890ff !important;
}

.components-layout-left .ant-collapse,
.components-layout-left .ant-collapse-content,
.components-layout-left .ant-collapse > .ant-collapse-item {
  border-color: #eee !important;
}

.components-layout-left .ant-collapse-content > .ant-collapse-content-box {
  padding: 16px 0;
}

.importUl{
  padding: 0;
  height: 800px;
  overflow-y: scroll;
}

.importUl::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 0px rgba(240, 240, 240, 0.5) !important;
  background: #c9d7f8 !important;
}
</style>
<style lang="less" scoped>
.ant-input-affix-wrapper {
  height: 45px;
  line-height: 45px;
  border: none;
}

.svg-nav-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  height: 320px;
  overflow-y: scroll;

  li {
    position: relative;
    width: 80px;
    height: 80px;
    padding: 19px;
    box-shadow: 1px 1px 5px #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #c9d7f8;
    margin: 0 0 -1px -1px;

/*    // & :hover {
    //   box-shadow: 1px 1px 5px #0090ff;
    // }*/

    img {
      display: block;
      width: 100%;
      height: 100%;
      border: 1px solid;
    }

    .title {
      display: none;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.4);
      color: #fff;
      height: 20px;
      line-height: 20px;
      font-size: 12px;
      text-align: center;
    }
  }

  & .two-item {
    li {
      width: calc(50% - 30px);
      height: 100px;
      margin-bottom: 25px;
      border-radius: 10px;

      img {
        width: auto;
        height: 100%;
      }
    }
  }
}

::v-deep .search-box input{
  background-color: #fff;
  border: none;
  color: #454545;
  padding-top: 10px;
  margin-left: 5px;
}
</style>
